<script setup lang="ts">
const props = defineProps({
  colors: {
    type: Array as PropType<string[]>,
    default: () => ['#7ab2fb', '#f090fa', '#7ab2fb', '#79ebd5', '#7ab2fb']
  },
  darkColors: {
    type: Array as PropType<string[]>,
    default: () => ['#000', '#000', 'transparent', 'transparent', '#000']
  }
});

const rainbowGradient = computed(() => {
  return props.colors.map((color, index) => `${color} ${10 + index * 5}%`).join(', ');
});

const darkStripes = computed(() => {
  return props.darkColors.map((color, index) => {
    if (index % 2 === 0) {
      return `${color} ${index * 3}%, ${color} ${7 + index * 3}%`;
    } else {
      return `${color} ${10 + (index - 1) * 2}%, ${color} ${12 + (index - 1) * 2}%`;
    }
  }).join(', ');
});
</script>

<template>

  <div
    class="absolute flex flex-col h-[calc(100vh-64px)] w-full p-0 transition-bg" :class="$attrs.class">
    <div class="absolute inset-0">
      <div class="jumbo w-full h-full absolute  opacity-30">

      </div>
    </div>
  </div>

</template>

<style scoped>

@keyframes jumbo {
    from {
        background-position: 50% 50%, 50% 50%;
    }
    to {
        background-position: 350% 50%, 350% 50%;
    }
}

.jumbo {
    --stripes: repeating-linear-gradient(
        100deg,
        #fff 0%,
        #fff 7%,
        transparent 10%,
        transparent 12%,
        #fff 16%
    );
    --stripesDark: repeating-linear-gradient(100deg, v-bind(darkStripes));
    --rainbow: repeating-linear-gradient(100deg, v-bind(rainbowGradient));
    background-image: var(--stripes), var(--rainbow);
    background-size: 300%, 200%;
    background-position: 50% 50%, 50% 50%;

    filter: invert(100%);

    mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

    pointer-events: none;
}

.jumbo::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: var(--stripes), var(--rainbow);
    background-size: 600%, 100%;
    animation: jumbo 60s linear infinite;
    background-attachment: fixed;
    mix-blend-mode: difference;
}

.dark .jumbo {
    background-image: var(--stripesDark), var(--rainbow);
    filter: blur(10px) opacity(50%) saturate(200%);
}
.dark .jumbo::after {
    background-image: var(--stripesDark), var(--rainbow);
}


</style>
